<template>
  <v-dialog
    v-model="dialog"
    transition="dialog-bottom-transition"
    :max-width="dialogWidth"
    scrollable
    fullscreen
  >
    <v-card>
      <v-card-title class="pa-0">
        <v-toolbar
          dark
          color="accent"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title dark>
            {{ $t('app.buttons.edit') }} {{ item.name }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn
            rounded
            color="primary"
            @click="saveItem"
          >
            {{ $t('app.buttons.save') }}
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <validation-observer
          ref="observer"
          v-slot="{ validate, reset }"
        >
          <form>
            <v-list
              three-line
            >
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    Nombre
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Nombre"
                      rules="required"
                    >
                      <v-text-field
                        v-model="item.name"
                        class="pl-5"
                        placeholder="Nombre"
                      />
                    </validation-provider>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ $t('app.headers.days') }}

                    <v-btn
                      small
                      outlined
                      @click="openDaysAdd"
                    >
                      <v-icon left>mdi-plus-circle</v-icon>

                      {{ $t('app.buttons.add') }}
                    </v-btn>
                  </v-list-item-title>

                  <v-data-table
                    :headers="daysHeaders"
                    :items="item.days"
                    :items-per-page="10"
                    :custom-filter="customFilter"
                  >
                    <template v-slot:item.is_open_seas="{ item }">
                      <v-chip
                        label
                        :color="item.is_open_seas ? 'accent' : 'grey lighten-1'"
                      >
                        {{ item.is_open_seas ? $t('app.general.yes') : $t('app.general.no') }}
                      </v-chip>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-col class="d-flex justify-end">
                        <v-btn
                          icon
                          @click="openDaysEdit(item)"
                        >
                          <v-icon>
                            mdi-pencil
                          </v-icon>
                        </v-btn>

                        <v-btn
                          icon
                          @click="openDaysDelete(item)"
                        >
                          <v-icon>
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </v-col>
                    </template>
                  </v-data-table>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </form>
        </validation-observer>
      </v-card-text>
    </v-card>

    <days-add-alert></days-add-alert>
    <days-edit></days-edit>
    <days-delete></days-delete>
  </v-dialog>
</template>

<script>
  import customFilter from '@/plugins/customFilter'

  export default {
    name: 'WeekEdit',
    mixins: [customFilter],
    components: {
      DaysAddAlert: () => import("../days/DayAddAlert"),
      DaysEdit: () => import("../days/DayEdit"),
      DaysDelete: () => import("../days/DayDelete"),
    },
    data () {
      return {
        dialog: false,
        item: {
          days: [],
          name: "",
        },
        daysHeaders: [
          {
            text: this.$t('app.headers.id'),
            value: 'id',
          },
          {
            text: "Aguas Abiertas",
            value: "is_open_seas"
          },
          {
            text: "Volumen Total",
            value: "total_volume",
          },
          {
            text: this.$t('app.headers.name'),
            value: 'name',
          },
          {
            text: '',
            value: 'actions',
            sortable: false,
          },
        ],
        training: {}
      }
    },
    mounted () {
      EventBus.$on('weeks-edit',  (item) => {
        console.log(item)
        this.item       = item.week
        this.training   = item.training
        this.item.days  = this.__sortDays(item.week.days)
        this.dialog     = true
      })

      EventBus.$on('reload-items-days-edit', (item) => {
        this.item = item
        this.item.days = this.__sortDays(item.days)
      })

      EventBus.$on('reload-items-days-delete', (item) => {
        this.item.days = this.item.days.filter(d => d.id != item.id)
        this.item.days = this.__sortDays(this.item.days)
      })
    },
    methods: {
      __sortDays(days) {
        return days.sort(
          (x, y) => (x.id - y.id)
        )
      },

      openDaysAdd() {
        EventBus.$emit('days-add-alert', this.item)
      },
      openDaysEdit(item) {
        EventBus.$emit('days-edit', {
          day: item,
          week: this.item
        })
      },
      openDaysDelete(item) {
        EventBus.$emit('days-delete', item)
      },

      async saveItem () {
        this.$refs.observer.validate()
          .then(async success => {
            if (!success) return

            this.toggleLoader()

            await this.$http.put(route(`weeks/${this.item.id}`), {
              data: this.item
            })
              .then(response => {
                if (response.body.data) {
                  let week = response.body.data
                  //this.processSuccess(response)
                  this.dialog = false
                  EventBus.$emit('reload-items-weeks-edit', this.item)
                } else {
                  this.processError(response)
                }
              }, error => {
                this.processError(error)
              })

            this.toggleLoader()
          })
      },
    },
  }
</script>

<style scoped>

</style>
